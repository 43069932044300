// Font Family
$roboto: 'Roboto', sans-serif;

// background ----------
$testi-dots : #C1C5CC;
$testi2-nav : #41444A;
$testi2-nav-icon : #8E96A4;
$testi2-pcolor: #c9c9c9;
$menuh-bg: #F5F8F9;
$services-itm-color: #FBF7EE;
$services-itm-color2: #F8F8F8;
$services-itm-color3: #F1F8F9;
$services-itm-color4: #F9F6F6;
$footer-bg : #1A1C20;
$footer-s-bg: #010101;
$visa-hover-bg: #D16C07;
$pg-title-br: #707070;
$error-subs: #F6F9FE;
$faq-bg: #F8FAFB;
$filter-border: #D9E5F9;
$country-popup: #0277C5;
// Colors ---------------
$white: #ffffff;
$grey: #F5F6F6;
$grey2: #F7F7F8;
$grey3: #F4F6F9;
$black: #222;
$hr-border-color:#eceff8;
$theme-color: #f52f2f;
$theme-color-blue: #2371ff;
$body-text-color: #6f6f6f;
$footer-text-color: #bcbfc9;
$border-color: #E7EAEF;
$border-color2: #9B8D6C;
$border-color3: #B1B2B8;
$border-color4: #8AA1A4;
$border-color5: #C9A8A8;
$social-icon: #647589;
$span-color: #9CA1AA;
$footer-social-color: #636770;
$sbuscribe-input: #969BA4;
$visa-border: #E89D4A;
$busines-br: #E9A65C;
$ads-btn: #FFE600;
// Heading Color
$heading-color: #1A1C20;

// footer 
$footer-text-color: #A6ABB2;

// Soft color
$black-soft:#1d284b;
$grey-border: #d6d8db;

// Responsive Variables
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
